<template>
  <div class="container" @click="bofang">
    <header>
      <a href="#" class="brand">
        <img class="logo" src="@/assets/image/logo.jpg" alt="">
      </a>
      <div class="menu-btn"></div>
      <div class="navigation">
        <div class="navigation-items">
          <a href="#">Home</a>
          <a href="#" >About</a>
          <a href="#" >Explore</a>
          <a href="#">Gallery</a>
          <a href="#">Contart</a>
        </div>
      </div>
    </header>

    <section class="home">
<!--      <img class="video-slide active" src="@/assets/image/home.gif" alt="">-->
<!--      <img class="video-slide" src="@/assets/image/home.gif" alt="">-->
      <video id="myVideo1" class="video-slide active" autoplay muted loop playsinline>
        <source src="@/assets/video/home.mp4" type="video/mp4">
      </video>
      <video id="myVideo2" class="video-slide" autoplay muted loop playsinline>
        <source src="@/assets/video/home2.mp4" type="video/mp4">
      </video>

      <div class="content active">
        <h1>帆洋<br><span>科技</span></h1>
        <p>专注游戏开发</p>
<!--        <a href="#">  Read More</a>-->
      </div>

      <div class="content">
        <h1>帆洋<br><span>科技</span></h1>
        <p>专注游戏开发</p>
        <!--        <a href="#">  Read More</a>-->
      </div>

<!--      <div class="media-icons">-->
<!--        <a href="#"><i class="fab fa-facebook-f"></i></a>-->
<!--        <a href="#"><i class="fab fa-instagram"></i></a>-->
<!--        <a href="#"><i class="fab fa-twitter"></i></a>-->
<!--      </div>-->

      <div class="slider-navigation">
        <div class="nav-btn active"></div>
        <div class="nav-btn"></div>
      </div>
    </section>

    <!--    <div class="header">-->
<!--        <div class="left">-->

<!--          <div class="logo" ></div>-->
<!--          <div class="company">帆洋科技</div>-->
<!--        </div>-->

<!--        <div class="right">-->

<!--          <div class="item selected">-->
<!--            首页-->
<!--          </div>-->

<!--          <div class="item">-->
<!--            产品介绍-->
<!--          </div>-->


<!--          <div class="item">-->
<!--            业务亮点-->
<!--          </div>-->


<!--          <div class="item">-->
<!--            联系我们-->
<!--          </div>-->

<!--        </div>-->
<!--    </div>-->
<!--    <div class="fill"></div>-->
<!--    <div class="banner">-->


<!--    </div>-->


    <div class="footer">
      <div>
        <div>电子邮箱:fanyang0617@163.com</div>
        <div>公司地址:北京市朝阳区广顺北大街33号院1号楼（五、十、十一层）1单元8611号</div>
      </div>

      <div class="line"></div>
      <div>北京帆洋科技有限公司</div>
      <div>京ICP备2024083275号</div>
      <div>版权所有2024-2024@Copyright</div>
    </div>

  </div>

</template>

<script setup>
import {ref, reactive, onMounted, nextTick} from "vue";

onMounted(() => {
  const menuBtn = document.querySelector(".menu-btn")
  const navigation = document.querySelector(".navigation")

  menuBtn.addEventListener("click",()=>{
    menuBtn.classList.toggle("active");
    navigation.classList.toggle("active");
  });



  const btns = document.querySelectorAll(".nav-btn")
  const slides = document.querySelectorAll(".video-slide")
  const contents = document.querySelectorAll(".content")

  var sliderNav= function(manual){
    btns.forEach((btn) => {
      btn.classList.remove("active")

    })

    slides.forEach((slide) => {
      slide.classList.remove("active")
    })

    contents.forEach((content) => {
      content.classList.remove("active")
    })

    btns[manual].classList.add("active")
    slides[manual].classList.add("active")
    contents[manual].classList.add("active")

  }

  btns.forEach((btn,i)=>{
    btn.addEventListener("click",()=>{
      sliderNav(i)
    })
  });


  // const video1 = document.getElementById('myVideo1');
  //
  // // 添加事件监听器，确保视频准备好后开始播放
  // video1.addEventListener('canplay', () => {
  //   video1.play();
  // });
  //
  // const video2 = document.getElementById('myVideo2');
  //
  // // 添加事件监听器，确保视频准备好后开始播放
  // video2.addEventListener('canplay', () => {
  //   video2.play();
  // });

  // setTimeout(() => {
  //   onBridgeReady()
  // }, 1000)




})

  function bofang() {

    const video1 = document.getElementById('myVideo1');
    const video2 = document.getElementById('myVideo2');

    video1.play();
    video2.play();
  }


function onBridgeReady(){
  //必须在微信Weixin JSAPI的WeixinJSBridgeReady才能生效
//关键代码

  const video1 = document.getElementById('myVideo1');
  const video2 = document.getElementById('myVideo2');
  if (window.WeixinJSBridge) {
    WeixinJSBridge.invoke('getNetworkType', {}, function (e) {
      video1.play()
      video2.play()
    }, false);
  } else {
    document.addEventListener("WeixinJSBridgeReady", function () {
      WeixinJSBridge.invoke('getNetworkType', {}, function (e) {
        video1.play()
        video2.play()
      });
    }, false);
  }
}



</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/style/style.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css";





    .footer {
      width: 100%;
      background-color: #323232;
      height: 206px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 5px;
      color:#B3B0B0;
      justify-content: center;
      font-size: 16px;


      .line {
        width: 100%;
        height: 1px;
        border-bottom: #B3B0B0 1px solid;

      }



    }


</style>
